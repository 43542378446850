<template>
  <div>
    <el-card class="box-card">
      <div class="item">
        <div>
          <div class="head-img">
            <div class="photo_contain">
              <div class="photo">
                <img :src="ruleForm.uphoto" alt="" />
                <div class="iconphoto">
                  <img src="~@ass/img/ico_ghtx@2x.png" alt="" />
                </div>

                <changePhotoimg
                  :size="7"
                  :isshow="true"
                  @complete="complete"
                  class="changePhotoimg"
                ></changePhotoimg>
              </div>
            </div>
          </div>
        </div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="80px"
          class="demo-ruleForm"
          @submit.native.prevent
        >
          <el-form-item label="手机号" prop="phone">
            <div class="phone-contain">
              <el-input
                placeholder="请输入手机号"
                v-model="ruleForm.umobile"
                maxlength="32"
                :disabled="true"
                class="phone-input"
              ></el-input>
              <span class="txt">*用于登录后台系统</span>
            </div>
          </el-form-item>
          <el-form-item
            v-if="
              !ruleForm.is_creater && userInfo.uid != ruleForm.uid && isAddRole
            "
            label="老师角色"
            prop="jg_group_id"
          >
            <el-checkbox-group v-model="ruleForm.jg_group_id">
              <el-checkbox
                v-for="item in authList"
                :key="item.jg_group_id"
                :label="item.jg_group_id"
              >
                {{ item.name }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="老师昵称" prop="name">
            <el-input
              placeholder="请输入老师昵称"
              v-model="ruleForm.uname"
              show-word-limit
              maxlength="16"
            ></el-input>
          </el-form-item>
          <el-form-item label="老师简介">
            <el-input
              type="textarea"
              placeholder="请填写老师简介"
              maxlength="255"
              show-word-limit
              :rows="7"
              v-model="ruleForm.ujianjie"
            ></el-input>
          </el-form-item>
          <el-form-item label="视频介绍">
            <div class="add-teacher-video">
              <div class="add" @click="dataBaseConfirm">+ 添加视频</div>
              <div class="video-info" v-if="ruleForm.video_id">
                <div class="vname">{{ ruleForm.file_name }}</div>
                <div class="v-detail">
                  <div class="t1">
                    {{
                      ruleForm.video_size
                    }}MB&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                    {{
                      (ruleForm.classHour || ruleForm.chapter_hour)
                        | formatTimeLength
                    }}
                  </div>
                  <div class="t2">
                    <span
                      @click="
                        previewSrc =
                          ruleForm.zmvidoe_url ||
                          ruleForm.video_url ||
                          ruleForm.url
                      "
                    >
                      播放
                    </span>
                    &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                    <span @click="removeVideo">移除</span>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="图文介绍" prop="name">
            <editor
              ref="editor"
              class="editor"
              v-model="ruleForm.details"
            ></editor>
          </el-form-item>
        </el-form>
        <div style="padding-left: 250px">
          <el-button @click="submitForm('ruleForm')">保存</el-button>
        </div>
      </div>
    </el-card>
    <!--资料库添加-->
    <fileListAndFileUpload
      v-if="showDataBaseConfirm"
      :showAudioMenu="false"
      @complete="dataBaseComplete"
      @close="dataBaseConfirm"
    ></fileListAndFileUpload>
    <!--视频预览-->
    <previewVideo
      v-if="previewSrc"
      :src="previewSrc"
      @close="previewSrc = ''"
    ></previewVideo>
  </div>
</template>

<script>
import editor from '@cm/editor'
import changePhotoimg from '@cm/base/changePhotoimg'
import background from '@/mixin/background'
import { mapState } from 'vuex'
import fileListAndFileUpload from '@cm/fileListAndFileUpload'

export default {
  components: {
    changePhotoimg,
    fileListAndFileUpload,
    editor,
  },

  mixins: [background],

  data() {
    return {
      ruleForm: {
        umobile: '',
        jg_group_id: [],
        uname: '',
        ujianjie: '',
        uphoto: '',
      },
      rules: {
        uname: [{ required: true, message: '请输入老师昵称', trigger: 'blur' }],
        umobile: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        jg_group_id: [
          { required: true, message: '请选择老师角色', trigger: 'change' },
        ],
      },
      authList: [],

      previewSrc: '',
      showDataBaseConfirm: false,
    }
  },

  computed: {
    ...mapState(['userInfo']),
    isAddRole() {
      return this.$store.state.roots.includes(61)
    },
  },

  methods: {
    complete(val) {
      this.ruleForm.uphoto = val
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const that = this
          that.$http({
            name: 'editUserInfo',
            url: 'User/editUserInfo',
            data: _.assign({}, this.ruleForm, {
              uid: that.ruleForm.uid,
              uname: that.ruleForm.uname,
              ujianjie: that.ruleForm.ujianjie,
              uphoto: that.ruleForm.uphoto,
              jg_group_id: that.ruleForm.jg_group_id,
            }),
            callback() {
              that.$router.go(-1)
            },
          })
        }
      })
    },
    // 获取角色信息
    getAuthList() {
      this.$http({
        url: '/Auth/userRolesList',
        data: {
          uid: this.ruleForm.uid,
        },
        callback: ({ data }) => {
          this.authList = data
          this.ruleForm.jg_group_id = data
            .filter((item) => !!item.is_select)
            .map((val) => val.jg_group_id)
        },
      })
    },

    // 资料库弹窗
    dataBaseConfirm() {
      this.showDataBaseConfirm = !this.showDataBaseConfirm
    },

    // 资料库选择回调
    dataBaseComplete(val) {
      this.ruleForm = _.assign({}, this.ruleForm, val, {
        video_id: val.serverId,
      })
    },

    removeVideo() {
      this.ruleForm = _.assign({}, this.ruleForm, {
        video_id: '',
      })
    },
  },

  created() {
    const v = this.$route.params.data || {}
    if (Object.keys(v).length === 0) {
      this.$router.replace({
        path: '/manageTeacher',
      })
    } else {
      this.ruleForm = _.assign({}, this.ruleForm, v)
    }
    this.getAuthList()
  },
}
</script>

<style lang="less" scoped>
.box-card {
  background: rgba(255, 255, 255, 1);
  margin: 20px;
  padding-bottom: 40px;
}
.phone-contain {
  display: flex;
  align-items: center;
}
.txt {
  font-size: 14px;
  color: rgba(155, 155, 155, 1);
  line-height: 19px;
  margin-left: 10px;
  white-space: nowrap;
}
/deep/ .el-radio__input.is-checked + .el-radio__label {
  color: #1b9d97;
}
/deep/ .el-radio__input.is-checked .el-radio__inner {
  border-color: #1b9d97;
  background: #1b9d97;
}
/deep/ .el-radio__inner:hover {
  border-color: #1b9d97;
}
.el-button {
  width: 126px !important;
  height: 42px !important;
  border-radius: 4px !important;
  color: #fff !important;
  border: 1px solid #1b9d97 !important;
  background-color: #1b9d97 !important;
}
.el-button:hover {
  color: #fff !important;
  border: 1px solid #1b9d97 !important;
  background-color: #1b9d97 !important;
}
/deep/ .el-card__body {
  padding: 0 79px;
}
/deep/ .head-img {
  .photo_contain {
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
    }
    .photo {
      cursor: pointer;
      position: relative;
      border-radius: 50%;
      height: 100px;
      width: 100px;
      background-color: #c0c4cc;
      .changePhotoimg {
        position: absolute;
        top: 0;
      }
      .iconphoto {
        position: absolute;
        height: 26px;
        width: 26px;
        border-radius: 50%;
        bottom: -11px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
/deep/ .el-textarea__inner:focus {
  border: 1px solid #1b9d97 !important;
}
.updata-head {
  height: 100px;
  width: 100px;
  position: absolute;
  bottom: 0;
  cursor: pointer;
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  width: 100%;
}
.carme {
  position: absolute;
  left: 50%;
  bottom: -15px;
  width: 26px;
  height: 26px;
  z-index: 99;
  margin-left: -13px;
  background: url('~@ass/img/1.1.6/ico_ghtx.png');
}
.phone-input.is-disabled .el-input__inner {
  color: #333 !important;
}
.add-teacher-video {
  display: flex;
  align-items: center;
  .add {
    width: 126px;
    height: 42px;
    cursor: pointer;
    font-size: 14px;
    color: #333333;
    border-radius: 2px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #e8e8e8;
  }
  .video-info {
    margin-left: 20px;
    line-height: 18px;
    .vname {
      font-size: 14px;
      color: #333333;
    }
    .v-detail {
      display: flex;
      margin-top: 10px;
      align-items: center;
      .t1 {
        font-size: 12px;
        color: #666666;
        margin-right: 20px;
        cursor: pointer;
      }
      .t2 {
        font-size: 12px;
        color: #0aa29b;
        cursor: pointer;
      }
    }
  }
}
/deep/ .el-form-item__content {
  margin-left: 116px !important;
}
</style>
